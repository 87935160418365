import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Input from 'common/components/Input';
import Container from 'common/components/UI/Container';
import SocialProfile from '../SocialProfile';

import {
  FooterWrapper,
  Newsletter,
  FooterNav,
  FooterNavItem,
} from './footer.style';
import { heart } from 'react-icons-kit/fa/heart';

const Footer = ({
  row,
  col,
  titleStyle,
  linkStyle,
  newsletterButton,
  copyrightStyle,
  contactItem,
  flexBox,
  contactTitle,
  contactInfo,
  noMargin,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        FOOTER_MENU {
          label
          path
        }
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container noGutter mobileGutter width="1200px">
        {/*<Box {...row}>*/}
          {/*<Box {...col}>*/}
          {/*  <Heading*/}
          {/*    as="h3"*/}
          {/*    content="Datenübernahmen aus Tradition"*/}
          {/*    {...titleStyle}*/}
          {/*  />*/}
          {/*  <a href="#1">*/}
          {/*    <Heading as="h3" content="Wir blicken auf über 25 Jahre Familientradition zurück." {...linkStyle} />*/}
          {/*  </a>*/}
          {/*</Box>*/}
          {/*<Box {...col}>*/}
          {/*  <Heading as="h3" content="A treat for your inbox" {...titleStyle} />*/}
          {/*  <Newsletter>*/}
          {/*    <Input*/}
          {/*      inputType="email"*/}
          {/*      placeholder="Email address"*/}
          {/*      iconPosition="right"*/}
          {/*      isMaterial={false}*/}
          {/*      className="email_input"*/}
          {/*      aria-label="email"*/}
          {/*    />*/}
          {/*    <Button {...newsletterButton} title="Subscribe" />*/}
          {/*  </Newsletter>*/}
          {/*</Box>*/}
        {/*</Box>*/}

        <Box {...row}>
          <Box {...col} >
             <Text content="Impressum" {...contactTitle} />
              <Text content="Datenübernahmen" {...contactInfo} />
              <Text content="für Rechtsanwälte und Notare" {...contactInfo} />
              <Text content="Fabian Unger" {...contactInfo} />
              <Text content="Bornheimer Straße 127" {...contactInfo} />
              <Text content="53119 Bonn" {...contactInfo} />

          </Box>
          <Box {...col} {...flexBox}>
            <Box {...contactItem}>
              <Text content="Haben Sie Fragen?" {...contactTitle} />
              <Text content="info@daten-unger.de" {...contactInfo} />
            </Box>
            <Box {...contactItem}>
              <Text content="Rufen Sie uns an" {...contactTitle} />
              <Text content="0228 230034" {...contactInfo} />
            </Box>
          </Box>
        </Box>

        <Box {...row} {...noMargin}>
          <Box {...col}>
            <Text
              as="span"
              content=""
              {...copyrightStyle}
            />
            {/*<Icon icon={heart} size={14} className="heart_sign" />*/}
          </Box>

          {/*<Box {...col} {...flexBox}>*/}
          {/*  <FooterNav>*/}
          {/*    {Data.portfolioJson.FOOTER_MENU.map((item, index) => (*/}
          {/*      <FooterNavItem key={`footer-nav-item-${index}`}>*/}
          {/*        <a href={item.path || '#1'}>{item.label}</a>*/}
          {/*      </FooterNavItem>*/}
          {/*    ))}*/}
          {/*  </FooterNav>*/}
          {/*</Box>*/}
        </Box>
          <Box {...row} {...noMargin}>
          <Box {...col}>
            <Text
              as="span"
              content="© 2024 Alle Rechte vorbehalten. "
              {...copyrightStyle}
            />

              <Text as="span" content=" Datenübernahmen für Rechtsanwälte und Notare - Fabian Unger" {...copyrightStyle} />

          </Box>

        </Box>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  titleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  newsletterButton: PropTypes.object,
  copyrightStyle: PropTypes.object,
  contactItem: PropTypes.object,
  flexBox: PropTypes.object,
  contactTitle: PropTypes.object,
  contactInfo: PropTypes.object,
  noMargin: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ml: '-15px',
    mr: '-15px',
    mb: ['0', '70px', '80px', '100px', '100px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 3],
    pl: '15px',
    pr: '15px',
    mb: ['40px', '0', '0', '0', '0', '0'],
  },
  titleStyle: {
    fontSize: ['16px', '18px'],
    fontWeight: '600',
    mb: ['20px', '25px'],
  },
  linkStyle: {
    fontSize: ['22px', '26px', '26px', '30px'],
    color: '#3444f1',
    mb: 0,
  },
  newsletterButton: {
    type: 'button',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    colors: 'primary',
    minHeight: 'auto',
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
  },
  flexBox: {
    flexBox: true,
    justifyContent: 'space-between',
    // flexWrap: 'wrap'
  },
  contactItem: {
    // width: 1 / 2
  },
  contactTitle: {
    fontSize: ['15x', '15px', '16px', '16px', '16px'],
    mb: '10px',
  },
  contactInfo: {
    fontSize: ['16x', '16px', '18px', '18px', '20px'],
    fontWeight: '500',
    mb: 0,
  },
  noMargin: {
    mb: '0',
  },
};

export default Footer;
